/* src/App.css */

/* Add the Google Fonts font-family definition */
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

/* Your existing CSS rules and styles */
body, html {
  margin: 0;
  font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Jost', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}