@font-face {
  font-family: 'GW2Font';
  src: url('./components/GW2Font.ttf') format('truetype');
}

body {
  background-color: #35363a;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

body {
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: #888;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-down {
  margin-top: 1em;
}

.center-class {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #202124;
}

.center-items {
  text-align: center;
}

.apis-flex {
  flex-direction: column;
  align-items: center;
}

.spec-icon {
  height: 25px;
}

.center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
  padding-top: 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center
}

/* Nav */

.app-nav {
  background-color: #202124;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  max-width: 100%;
}

.app-nav,
.nav-login-register,
.nav-profile-logout {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
}

.nav-center {
  margin-left: -70px;
}

.nav-a {
  color: white;
  text-decoration: none;
  margin-right: 10px;
}

li a.nav-a {
  position: relative;
}

li a.nav-a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background: #d70000;
  transition: width 0.3s ease;
}

li a.nav-a:hover:after {
  width: 100%;
}

li a.nav-a:hover {
  color: #d70000;
}

.left-nav {
  margin-right: 150px;
}

.right-nav {
  margin-left: 150px;
}

.right-nav>li {
  font-weight: 500;
}

/* Login & Register */

label {
  display: block;
  margin-top: 10px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* Search Bar */

.search-container {
  display: flex;
  justify-content: center;
  margin: 10px 0 0 0;
}

.search-input {
  font-family: 'Jost';
  width: 16em;
  background-color: #202124;
  color: white;
  border: none;
  padding: 0px 10px;
  margin: 5px;
  font-size: 1.5em;
}

.search-input::placeholder {
  font-family: 'Jost';
  color: rgb(163, 163, 163);
  font-size: 0.9em;
  /* Change the color to your desired color */
}

/* Logo */
.logo-div {
  position: relative;
  width: 53px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.logo-dragon {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 40px;
  height: 40px;
  filter: drop-shadow(4px 2px 0px rgba(0, 0, 0, 1));
}

.logo-cog {
  position: absolute;
  top: -26%;
  left: 4%;
  z-index: 1;
  width: 50px;
  height: 50px;
  filter: drop-shadow(1.5px 2px 0px rgba(0, 0, 0, 1));
}

.logo-dragon-loading,
.logo-cog-loading {
  top: 0px;
}

.logo-spin:hover .logo-cog {
  animation: spin 15s linear infinite;
  animation-iteration-count: infinite;
  transform: translate(-50%, -50%) rotate(0deg);
}

.logo-spin-loading {
  height: 50px;
}

.logo-spin-loading .logo-cog {
  animation: spin 15s linear infinite;
  animation-iteration-count: infinite;
  transform: translate(-50%, -50%) rotate(0deg);
}

.form-control {
  background-color: #202124;
  color: #d9d9d9;
  border: none;
  padding: 5px 10px;
  font-size: 0.94em;
  margin: 3px;
}

input:-webkit-autofill,
input:focus:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #202124 inset;
  -webkit-text-fill-color: #d9d9d9;
}

.basic-button {
  cursor: pointer;
  background-color: #202124;
  color: #d9d9d9;
  border: none;
  margin: 10px;
  padding: 5px 10px;
  font-size: 1em;
}

.info-button {
  width: 160px;
}

.basic-button.active {
  box-shadow: 0 0 7px 2px rgba(255, 255, 255, 0.2);
}

.basic-button:hover {
  background-color: #2c2c30;
  box-shadow: 0 0 7px 2px rgba(255, 255, 255, 0.2);
}

.disabled-button:disabled {
  cursor: default;
  background-color: #202124;
  color: #757575;
  border: none;
  margin: 10px;
  padding: 5px 10px;
  font-size: 1em;
}

.delete-button {
  cursor: pointer;
  background-color: #510303;
  color: #d9d9d9;
  border: none;
  margin: 10px;
  padding: 5px 10px;
  font-size: 1em;
}

.delete-button:hover {
  background-color: #aa0404 !important;
}

.profile-apis-first-child:hover,
.profile-storage-first-child:hover {
  cursor: pointer;
  transition: all 0.3s ease;
}

.profile-apis-first-child:hover {
  border-bottom: 2px dashed;
  margin-top: 2px;
  filter: brightness(3);
  transition: filter 0.2s;
}

.profile-storage-first-child:hover {
  border-bottom: 2px dashed;
  margin-top: 2px;
  filter: brightness(1.3);
  transition: filter 0.2s;
}


.cancel-button {
  cursor: pointer;
  background-color: #FFCC00;
  color: #202124;
  border: none;
  margin: 10px;
  padding: 5px 10px;
  font-size: 1em;
}

.link-underline {
  cursor: pointer;
  text-decoration: underline;
}

p span {
  display: block;
}

/* Loading Logo */

.logo-loading-div {
  position: relative;
  top: 250px;
  transform: translate(-50%, -50%);
}

.logo-loading-div-build {
  position: relative;
  top: 287px;
  transform: translate(-50%, -50%);
}

.logo-equipment-width {
  width: 484px;
}

.logo-build-width {
  width: 643px;
}



.logo-loading-build {
  right: 136px;
}

.logo--loading-dragon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 120px;
  height: 120px;
  filter: drop-shadow(4px 2px 0px rgba(0, 0, 0, 1));
}

.logo-loading-cog {
  position: absolute;
  top: -74px;
  right: -77px;
  transform: translate(-50%, -50%) rotate(0deg);
  filter: drop-shadow(4px 2px 0px rgba(0, 0, 0, 1));
  z-index: 1;
  width: 150px;
  height: 150px;
  animation: spin 50s linear infinite;
  animation-iteration-count: infinite;
}

.logo-loading-googly {
  position: absolute;
  top: -9px;
  right: 5px;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;
  z-index: 3;
  width: 20px;
  height: 20px;
  animation: spin2 1s linear infinite;
  animation-iteration-count: infinite;
  will-change: transform;
}

@keyframes spin2 {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.gw2-logo-style {
  font-family: 'GW2Font';
  font-size: 25px;
  color: #aa0404;
  -webkit-text-fill-color: #aa0404;
}

.geary-logo-style {
  font-family: 'GW2Font';
  font-size: 25px;
}

.api-keys {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-svg {
  width: 35px;
  margin-right: 10px;
}

.sun-moon:hover,
.contact-svg img:hover,
.nav-a:hover .link-svg {
  cursor: pointer;
  filter: invert(87%) sepia(61%) saturate(5291%) hue-rotate(356deg) brightness(98%) contrast(115%);
}

.form-group label {
  margin-left: 5px;
}

.form-button:disabled {
  cursor: default;
  opacity: 0.6;
  pointer-events: none;
}

.footer {
  background-color: #202124;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-top: auto;
  padding: 6px;
  list-style-type: none;
}

.footer-left {
  text-align: left;
  margin: -20px 0px 12px 15px;
}

.footer-middle {
  display: flex;
  justify-content: center;
  margin-block-end: 0;
}

.footer-middle a {
  margin: 0 10px;
}

.about-box {
  margin-top: 1em;
  padding: 0em 1em 2em 1em;
  background-color: #202124;
  box-shadow: 0 0 7px 2px rgba(204, 204, 204, 0.2);
  color: #d3d3d3;
  margin: 0 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.support-box {
  background-color: #191d20;
  align-items: center;
  box-shadow: 0 0 7px 2px rgba(204, 204, 204, 0.2);
  color: #d3d3d3;
  width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.2em;
}

.profile-box {
  background-color: #202124;
  box-shadow: 0 0 7px 2px rgba(204, 204, 204, 0.2);
  color: #d3d3d3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.profile-box button {
  background-color: #35363a;
}

iframe {
  display: none;
}

.news-box {
  margin: 0 25%;
}

.line {
  width: 250px;
  border-top: 1px solid rgb(255, 255, 255);
  margin-bottom: 10px;
  margin-top: -15px;
}

.breadcrumb {
  font-weight: 500;
}

.link-svg {
  margin-right: -3px;
  width: 20px;
  margin-bottom: -3px;
}

.share-div {
  position: absolute;
  background-color: white;
  border: 1.5px solid rgb(255, 255, 255);
  box-shadow: 0 0 7px 2px rgba(152, 152, 152, 0.2);
  padding: 0px 0px 0px 10px !important;
  margin-top: 40px !important;
  width: 250px;
  left: 50%;
  transform: translateX(-50%);
}

.share-div>div {
  padding: 4px;
  margin-bottom: -6px;
}

.share-input {
  margin: 0;
  padding: 0;
  height: 36px;
  width: 175px;
  margin-right: 10px;
  padding-top: 3px;
  border: none;
  background: #35363a;
  color: white;
}

.share-copy {
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  margin-top: 5px;
  padding: 2px 0px;
  width: 100px;
  border: 1px solid;
}

.padding-box {
  padding: 1px 4px;
  transition: transform 0.2s, filter 0.2s;
}

.padding-box:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.show-content {
  opacity: 1;
  max-height: 100px;
  /* Set a reasonable max-height */
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.hide-content {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.arrow-logic {
  position: absolute;
  width: 30px;
  margin-right: 22px;
  margin-bottom: 3px;
  bottom: 0;
  right: 0;
  display: flex;
}

.arrow-text {
  font-size: 13px;
  margin-top: -4px;
  padding-right: 3px;
}

.arrow-svg {
  width: 15px;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #aa0404 #d3d3d3;
}

/* Custom scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px;

}

.custom-scrollbar::-webkit-scrollbar:horizontal {
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background-color: #aa0404;
  /* box-shadow: inset 0 0 5px #35363a; */
}

.custom-scrollbar::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track:horizontal {
  background-color: #d3d3d3;
}

.facts-div-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.facts-div-profile>div:first-child {
  text-align: end;
}

.profile-names {
  margin-right: 5px;
}

.profile-characters {
  margin: 0px !important;
}

.characters-section {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.characters-profile-characters {
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
}

.characters-section.show {
  padding-bottom: 10px;
  max-height: 1000px;
  /* Set an appropriate value to allow the content to expand */
}

.characters-profile-characters.show {
  transform: translateY(0);
}

.alert-danger {
  color: #f16565;
}

.video-body {
  cursor: pointer;
}

.video-body:hover img {
  filter: invert(119%) sepia(82%) saturate(5978%) hue-rotate(0deg) brightness(77%) contrast(107%);
}

.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.game-button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
  outline: none;
  width: 30px;
  transition: transform 0.2s, filter 0.2s;
}

.game-button:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.rainbow-box {
  position: relative;
  overflow: hidden;
}

.rainbow-box::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red, orange, yellow, green, blue, indigo, violet);
  background-size: 400%;
  animation: move 4s linear infinite;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.rainbow-box:hover::before {
  opacity: 1;
}

.mobile-rainbow-box {
  position: relative;
  overflow: hidden;
}

.mobile-rainbow-box::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet, red, orange, yellow, green, blue, indigo, violet);
  background-size: 400%;
  animation: move 4s linear infinite;
  opacity: 1;
  transition: none;
}

@keyframes move {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.rainbow-box:hover::before {
  opacity: 1;
}

/* LIGHT MODE */

body.light-theme {
  background-color: #e7e6e6;
  color: #35363a;
}

body.light-theme .app-nav,
body.light-theme .home-empty-search-box,
body.light-theme .about-box,
body.light-theme .profile-box,
body.light-theme .search-input,
body.light-theme ::placeholder,
body.light-theme .center-class,
body.light-theme .pagination-item:not(.active),
body.light-theme .acc-info,
body.light-theme .char-info,
body.light-theme .equipment,
body.light-theme .build,
body.light-theme .template-text,
body.light-theme .basic-button,
body.light-theme .wiki-button,
body.light-theme .wiki-button-traits,
body.light-theme .basic-button-search,
body.light-theme .dropdown>ul>li,
body.light-theme .form-control,
body.light-theme .footer,
body.light-theme .form-button:disabled,
body.light-theme .itemstats,
body.light-theme .profile-box button,
body.light-theme .equipment-save,
body.light-theme .support-box {
  background-color: rgb(255, 255, 255);
  color: #35363a;
}

body.light-theme .rainbow-box:hover {
  filter: brightness(1.3);
}

body.light-theme .video-icon {
  filter: brightness(0.2);
}

body.light-theme .video-body:hover img {
  filter: invert(119%) sepia(82%) saturate(5978%) hue-rotate(0deg) brightness(77%) contrast(107%);
}

body.light-theme .profile-box button:hover {
  color: white;
}

body.light-theme .custom-checkbox .checkmark {
  border: 0px solid #202124;
}

body.light-theme .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #f16565 #ffffff;
}

body.light-theme .custom-scrollbar::-webkit-scrollbar-thumb,
body.light-theme .custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background-color: #f16565;
}

body.light-theme .custom-scrollbar::-webkit-scrollbar-track,
body.light-theme .custom-scrollbar::-webkit-scrollbar-track:horizontal {
  background-color: #ffffff;
  border: 1px solid #35363a;
}

body.light-theme .delete-button {
  background-color: #510303;
  color: white;
}


body.light-theme .line {
  border-top: 1px solid rgb(0, 0, 0);
}

body.light-theme .sun-moon:hover {
  filter: invert(0%) sepia(33%) saturate(5291%) hue-rotate(339deg) brightness(98%) contrast(115%);
}

body.light-theme .plus-minus-button img,
body.light-theme .contact-svg img,
body.light-theme .news-svg img,
body.light-theme .arrow-svg {
  filter: invert(80%) sepia(2%) saturate(4015%) hue-rotate(188deg) brightness(102%) contrast(83%);
}

body.light-theme .link-svg,
body.light-theme .wiki-button img {
  filter: invert(75%) sepia(97%) saturate(4%) hue-rotate(28deg) brightness(103%) contrast(100%);
}

body.light-theme .contact-svg img:hover {
  filter: invert(80%) sepia(33%) saturate(5291%) hue-rotate(339deg) brightness(98%) contrast(115%);
}

body.light-theme .tooltip-container {
  color: #ffffff;
}

body.light-theme .nav-a {
  color: #35363a;
}

body.light-theme .basic-button,
body.light-theme .form-control {
  box-shadow: 0 0 7px 2px rgba(152, 152, 152, 0.2);
}

body.light-theme .up-down-arrow {
  filter: invert(12%) sepia(63%) saturate(4962%) hue-rotate(354deg) brightness(99%) contrast(108%);
}

body.light-theme .pagination-item:hover,
body.light-theme .center-class:hover,
body.light-theme .basic-button:hover,
body.light-theme .basic-button-search:hover,
body.light-theme .dropdown>ul>li:hover {
  background-color: #b9b9b9;
  transition: background-color 0.2s;
}

body.light-theme .yellow-highlight {
  color: #aa0404;
  -webkit-text-fill-color: #aa0404;
}

body.light-theme input:-webkit-autofill {
  -webkit-text-fill-color: #35363a;
  -webkit-box-shadow: 0 0 0 100px #ffffff inset;
}

body.light-theme .pagination-item:hover {
  background-color: #aa0404;
  color: white;
  box-shadow: 0 0 7px 2px rgba(204, 204, 204, 0.2);
}

body.light-theme .accounts-hover:hover,
body.light-theme .basic-button-search.active {
  background-color: #f1cccc;
  box-shadow: 0 0 7px 2px rgba(254, 137, 137, 0.7);
  transition: background-color 0.3s, box-shadow 0.3s;
}