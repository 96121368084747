.equipment-build-flex {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding-top: 5px;
  justify-content: center;
  margin-top: -20px;
}

.equipment-build-flex-fashion {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding-top: 5px;
  justify-content: center;
}

.build {
  min-width: 350px;
  height: 652px;
  padding: 20px;
  background-color: #202124;
  margin: 4px;
}

.dropdown {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
}

.dropdown>ul>li {
  background-color: #191919;
  color: white;
}

.dropdown>ul {
  margin-top: 6px;
}

.dropdown>ul>li:hover {
  cursor: pointer;
  background-color: #35363a;
  color: white;
}

.dropdown-button {
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  margin-right: 2%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-55%);
  background-color: #f1f1f1;
  padding: 0;
  margin: 2% 0 0 0;
  width: auto;
  max-width: 45%;
}

.dropdown-menu li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  height: auto;
}

.dropdown-menu li:hover {
  background-color: #ddd;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.go-back-button {
  position: fixed;
  top: 10%;
  transform: translateY(-50%);
  left: 16%;
  color: white;
  text-decoration: none;
  font-size: large;
  padding: 2px;
  border: 2px solid white;
  border-radius: 10px;
}

.flex {
  display: flex;
}

.cursor {
  cursor: default;
}

.tooltip-container {
  font-weight: 500;
  min-width: 15%;
  z-index: 20;
}

.remove-margin-right {
  margin-right: -40px;
}

.char-info {
  background-color: #202124;
  min-width: 650px;
  padding: 10px;
}

.acc-info {
  background-color: #202124;
  padding: 10px;
  margin: 17px 0 15px 0;
}

.acc-info-new {
  position: relative;
  background-color: #202124;
  padding: 10px;
  margin-bottom: 20px;
}

.info-size {
  height: 20px;
}

.info-centered {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}


.api-key-profile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Checkbox */
.api-checkbox {
  display: none;
}

.custom-checkbox {
  margin: 0;
  margin-left: 8px;
  cursor: pointer;
}

.character-checkbox {
  position: relative;
  margin-left: 182px;
  top: 31px;
  margin-top: -25px;
}

.custom-checkbox .checkmark {
  display: inline-block;
  border: 0px solid #d3d3d3;
  background-color: #aa0404;
  transition: all 0.3s ease;
  padding-top: 2px;
}

.custom-checkbox .checkmark:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.custom-checkbox .api-checkbox:checked+.checkmark,
.darkgreen {
  background-color: darkgreen;
}

.custom-checkbox .checkmark {
  vertical-align: middle;
  margin-right: 8px;
  margin-left: -8px;
}

/* Character Box */

.characters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 20%;
}

.character {
  min-width: 228px;
  max-width: 228px;
}

.character-box {
  margin: 1%;
  padding: 3%;
}

.character-name {
  font-size: 1.4em;
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.character-name-div {
  height: 50px;
}

.character-link {
  color: #ffffff;
  text-decoration: none;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22.67px;
  margin: 2% 1% 0 2%;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #808080;
  -webkit-transition: .4s;
  transition: .4s;
  transition: filter 0.2s;
}

.slider:hover {
  filter: brightness(1.4);
}

.off-text {
  color: #808080;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17.34px;
  width: 17.34px;
  left: 2.33px;
  bottom: 2.33px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 15px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Profile */

.up-down-arrow {
  filter: invert(93%) sepia(42%) saturate(4211%) hue-rotate(307deg) brightness(123%) contrast(104%);
  cursor: pointer;
}

.show-hide-button {
  cursor: pointer;
  background-color: #35363a;
  border: none;
  color: white;
}

.show-hide-button:hover img {
  filter: invert(91%) sepia(65%) saturate(6000%) hue-rotate(-1deg) brightness(103%) contrast(94%)
}

.show-hide-button:hover {
  color: #aa0404;
}

.wiki-button,
.wiki-button-traits {
  position: absolute;
  padding: 3px;
  font-size: 16px;
  border: 1px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 2;
  background-color: #202124;
  color: #d9d9d9;
  border: none;
  font-size: 1em;
  border: 2px dashed #d36565;
  box-shadow: 0 2px 7px 2px rgba(152, 152, 152, 0.2);
}

.wiki-button {
  margin-left: 0px;
  margin-top: -25px;
  width: 96px;
}

.wiki-button-traits {
  margin-left: -50px;
  margin-top: 5px;

}

.wiki-button img,
.wiki-button-traits img {
  padding-left: 5px;
  width: 15px;
}

.wiki-button.active {
  box-shadow: 0 0 7px 2px rgba(255, 255, 255, 0.2);
}

.wiki-button:hover {
  background-color: #2c2c30;
  box-shadow: 0 0 7px 2px rgba(255, 255, 255, 0.2);
}

/* Character */

.character-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
}

.font-size-20px {
  font-size: 20px;
}

.home-welcome {
  font-size: 1.5em;
}

.font-size-22px {
  font-size: 22px;
}

/* Hide the API key on small screens */
@media (max-width: 900px) {

  /* .facts-div-profile>div:first-child {
    max-width: 230px;
  } */

  .acc-info-new {
    margin-bottom: 0px;
  }

  .profile-characters>div>a>div>.character-name-div.row>div {
    max-width: 80px;
  }

  .character-checkbox {
    margin-left: 80px;
  }

  .profile-characters>div>a>div {
    height: 95px;
    margin: 3px 1px;
  }

  .equipment-build-flex {
    transform: scale(100%);
    margin-top: 0;
  }

  .character-col {
    margin-right: 0;
    padding-bottom: 20px;
    transition: padding 0.5s ease;
  }

  .character-col-close {
    padding-bottom: 0px;
    transition: padding 0.5s ease;
  }

  .copy-character {
    margin-top: 5px;
  }

  .search-input,
  .home-empty-search-box {
    font-size: 1em;
  }

  .home-welcome {
    font-size: 1.3em;
  }

  .gw2-logo-style,
  .geary-logo-style {
    font-size: 20px;
  }

  .content {
    margin-top: 0em;
  }

  .api-key-profile {
    display: none;
  }

  .api-form-control {
    width: 200px;
  }

  .right-nav,
  .left-nav {
    margin: 0 20px;
    padding-top: 10px;

  }

  .equipment-col-1 {
    width: 0;
  }


  .template-text {
    display: none;
  }

  .traits-box {
    width: 300px;
    padding-left: 0;
  }

  .cropped-spec-img {
    margin-top: -120px;
    margin-left: -62px;
  }

  .build {
    overflow: hidden;
  }

  .tooltip-container {
    transform: none;
  }

  .logo-build-width {
    max-width: 355px;
  }

  .char-info {
    flex-direction: column;
    min-width: 330px !important;
  }

  .acc-info {
    flex-direction: column;
    min-width: 240px !important;
  }

  .characters {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 0%;
  }

  .characters-boxes,
  .character {
    min-width: 115px;
    max-width: 115px;
    font-size: 10px;
    margin: 1px;
  }

  .custom-scrollbar>div>div>a>div>div.flex.space-between.row>div>div {
    font-size: 10px;
  }

  .character-name-div {
    height: 40px;
  }

  .flex.space-between.row>div.flex.column.col>div>img {
    width: 35px !important;
  }

  .acc-name-font-mobile {
    font-size: 20px !important;
  }

  .acc-info-font-mobile {
    justify-content: center;
    font-size: 15px !important;
  }

  .footer-left {
    margin: 0;
    padding: 10px 0;
    text-align: center;
  }

  .build {
    height: 700px;
    padding: 20px 0 !important;
  }

  .equipment,
  .build {
    width: 355px !important;
    margin-right: 0px;
  }

  .about-box {
    margin: 0 10px;
    margin-bottom: 20px;
  }

  .api-info-field {
    display: table-caption;
  }

  .flex-row-center {
    margin: 0 25%;
  }

  .search-news {
    padding: 1% 5% 0% 5%;
  }

  .itemstats {
    width: 307px;
  }

  .logo-equipment-width {
    width: 350px;
  }

  .traits-box {
    padding-left: 50px;
  }

  .cropped-spec-img {
    margin-left: -95px;
  }

  .padding-top {
    padding-top: 10px;
  }

  .dropdown-popup {
    visibility: hidden;
  }

  .switch-size-left {
    font-size: 13px;
    padding: 15px 5px 5px 0px;
  }

  .switch-size-right {
    font-size: 13px;
    padding: 5px 5px 0px 0px;
  }

  .thin-hr {
    width: 75%;
  }

  .share-div {
    flex-direction: row;
  }

  .api-right {
    margin-right: 0px;
  }
}