.spec-box {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
}

.cropped-spec-img-div {
    box-sizing: border-box;
    max-width: 644px;
    max-height: 138px;
    overflow: hidden;
}

.cropped-spec-img {
    margin-top: -120px;
}

.traits-box {
    position: absolute;
    margin: auto;
    width: 515px;
    padding-top: 48px;
    padding-left: 200px;
}

.dropdown-menu {
    z-index: 5;
}

.minor-trait-icon {
    clip-path: polygon(50% 0%, 95% 26%, 96% 75%, 50% 101%, 7% 73%, 7% 28%)
}

.minor-trait-icon,
.major-trait-icon {
    max-width: 64px;
    width: 58%;
    transition: transform 0.2s, filter 0.2s;
}

.minor-trait-icon:hover,
.major-trait-icon:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
}

.major-col {
    margin-top: -8%;
}

.inactive-trait {
    opacity: 0.5;
    filter: brightness(50%);
}

.skills-center {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
    margin-bottom: 10px;
}

.skill-box {
    display: flex;
    margin: 3px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
}

.skill-box-container .skill-box {
    transition: transform 0.2s, filter 0.2s;
}


.skill-box-container .skill-box:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
}

.elite-spec-icon .wiki-button-traits {
    margin-left: -60px;
}

.elite-spec-icon {
    transition: filter 0.2s;
}


.elite-spec-icon:hover {
    filter: brightness(1.2);
}


.center-land,
.center-water {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.template-container {
    margin-top: 15px;
    text-align: center;
}

.template-text {
    color: white;
    background-color: #191919;
    padding: 10px;
    font-size: 14px;
    border: none;
    width: 82%;
    margin-right: 6px;
}

.template-button {
    color: white;
    padding: 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
}



/* TraitsTooltip */

.build-popup {
    background-color: #000000e0;
    padding: 10px;
    white-space: nowrap;
    font-size: smaller;
    white-space: pre-wrap;
    text-align: left;
    max-width: 350px;
}

.yellow-highlight {
    color: #ffef89;
}

.spell-trait-name {
    color: #ffcd77;
    font-size: medium;
}

.fact-font-size {
    font-size: small;
}

.fact-font-size img {
    width: 25px;
    height: 25px;
}

.spell-trait-name {
    position: relative;
    padding-right: 50px;
}

.recharge-div {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.recharge-value {
    flex-grow: 1;
}

.recharge-img {
    max-height: 16px;
}

.api-right {
    justify-content: flex-end;
    margin-right: 100px;
}

.facts-div {
    display: flex;
    align-items: center;
}

.mouse-click {
    max-height: 16px;
}

.yellow-popup {
    color: #ffcd77;
}

.save {
    color: #088508;
}

.spec-name {
    position: relative;
    text-align: left;
    right: -22px;
    bottom: -125px;
    font-weight: 500;
    margin-top: -19px;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 1));
}

@media (max-width: 900px) {

    .custom-checkbox {
        font-size: 13.5px;
    }

    .profile-names {
        font-size: 16px;
    }

    .build {
        min-width: 350px !important;
        padding: 5px !important;
    }

    .spec-name {
        position: initial;
        text-align: center;
        margin-top: 0;
        filter: none;
    }

    .minor-trait-icon {
        margin-top: 12px;
    }

    .minor-trait-icon,
    .major-trait-icon {
        width: 70%;
    }

    .traits-box {
        margin-top: -12px;
    }
}

@media (min-width: 551px) {
    .spec-name {
        color: white;
    }
}