.class-hover {
  transition: background-color 0.3s ease-out;
}

/* Engineer */
.engineer-text {
  color: #98692c;
}

input:checked+.engineer-switch,
.engineer-border {
  background-color: #98692c;
}

.engineer-border:hover {
  background-color: #65451d;
}

.engineer-lightning-border {
  border: 1.5px solid rgb(152, 105, 44);
  box-shadow: 0 0 10px 2px rgba(152, 105, 44, 0.3);
}

.engineer-filter {
  filter: invert(42%) sepia(14%) saturate(2018%) hue-rotate(354deg) brightness(97%) contrast(86%);
}

.engineer-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(26%) sepia(51%) saturate(548%) hue-rotate(353deg) brightness(95%) contrast(95%);
}

/* Necromancer */
.necromancer-text {
  color: #3f5847;
}

input:checked+.necromancer-switch,
.necromancer-border {
  background-color: #3f5847;
}

.necromancer-border:hover {
  background-color: #1a241d;
}

.necromancer-lightning-border {
  border: 1.5px solid rgb(63, 88, 71);
  box-shadow: 0 0 10px 2px rgba(26, 36, 29, 0.3);
}

.necromancer-filter {
  filter: invert(30%) sepia(20%) saturate(491%) hue-rotate(87deg) brightness(98%) contrast(93%);
}

.necromancer-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(9%) sepia(17%) saturate(801%) hue-rotate(85deg) brightness(95%) contrast(91%);
}


/* Thief */
.thief-text {
  color: #495578;
}

input:checked+.thief-switch,
.thief-border {
  background-color: #495578;
}

.thief-border:hover {
  background-color: #293045;
}

.thief-lightning-border {
  border: 1.5px solid rgb(73, 85, 120);
  box-shadow: 0 0 10px 2px rgba(41, 48, 69, 0.3);
}

.thief-filter {
  filter: invert(33%) sepia(9%) saturate(1901%) hue-rotate(187deg) brightness(91%) contrast(89%);
}

.thief-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(16%) sepia(14%) saturate(1403%) hue-rotate(187deg) brightness(95%) contrast(92%);
}

/* Elementalist */
.elementalist-text {
  color: #a3362e;
}

input:checked+.elementalist-switch,
.elementalist-border {
  background-color: #a3362e;
}

.elementalist-border:hover {
  background-color: #6f251f;
}

.elementalist-lightning-border {
  border: 1.5px solid rgb(163, 54, 46);
  box-shadow: 0 0 10px 2px rgba(111, 37, 31, 0.3);
}

.elementalist-filter {
  filter: invert(33%) sepia(17%) saturate(3257%) hue-rotate(330deg) brightness(89%) contrast(89%);
}

.elementalist-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(16%) sepia(29%) saturate(3153%) hue-rotate(334deg) brightness(100%) contrast(94%);
}

/* Warrior */
.warrior-text {
  color: #bc8d16;
}

input:checked+.warrior-switch,
.warrior-border {
  background-color: #bc8d16;
}

.warrior-border:hover {
  background-color: #896610;
}

.warrior-lightning-border {
  border: 1.5px solid rgb(188, 141, 22);
  box-shadow: 0 0 10px 2px rgba(137, 102, 16, 0.3);
}

.warrior-filter {
  filter: invert(63%) sepia(34%) saturate(6911%) hue-rotate(19deg) brightness(99%) contrast(83%);
}

.warrior-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(32%) sepia(49%) saturate(3225%) hue-rotate(38deg) brightness(88%) contrast(87%);
}

/* Ranger */
.ranger-text {
  color: #8ea53a;
}

input:checked+.ranger-switch,
.ranger-border {
  background-color: #8ea53a;
}

.ranger-border:hover {
  background-color: #627228;
}

.ranger-lightning-border {
  border: 1.5px solid rgb(142, 165, 58);
  box-shadow: 0 0 10px 2px rgba(98, 114, 40, 0.3);
}

.ranger-filter {
  filter: invert(56%) sepia(58%) saturate(441%) hue-rotate(32deg) brightness(98%) contrast(86%);
}

.ranger-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(38%) sepia(66%) saturate(383%) hue-rotate(32deg) brightness(93%) contrast(92%);
}

/* Mesmer */
.mesmer-text {
  color: #724192;
}

input:checked+.mesmer-switch,
.mesmer-border {
  background-color: #724192;
}

.mesmer-border:hover {
  background-color: #4a2a5e;
}

.mesmer-lightning-border {
  border: 1.5px solid rgb(114, 65, 146);
  box-shadow: 0 0 10px 2px rgba(74, 42, 94, 0.3);
}

.mesmer-filter {
  filter: invert(30%) sepia(11%) saturate(3614%) hue-rotate(235deg) brightness(94%) contrast(87%);
}

.mesmer-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(19%) sepia(30%) saturate(1273%) hue-rotate(234deg) brightness(88%) contrast(92%);
}

/* Guardian */
.guardian-text {
  color: #67aecb;
}

input:checked+.guardian-switch,
.guardian-border {
  background-color: #67aecb;
}

.guardian-border:hover {
  background-color: #4d8297;
}

.guardian-lightning-border {
  border: 1.5px solid rgb(103, 174, 203);
  box-shadow: 0 0 10px 2px rgba(77, 130, 151, 0.3);
}

.guardian-filter {
  filter: invert(72%) sepia(41%) saturate(432%) hue-rotate(155deg) brightness(83%) contrast(91%);
}

.guardian-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(47%) sepia(21%) saturate(792%) hue-rotate(151deg) brightness(94%) contrast(90%);
}

/* Revenant */
.revenant-text {
  color: #572435;
}

input:checked+.revenant-switch,
.revenant-border {
  background-color: #572435;
}

.revenant-border:hover {
  background-color: #240e15;
}

.revenant-lightning-border {
  border: 1.5px solid rgb(87, 36, 53);
  box-shadow: 0 0 10px 2px rgba(36, 14, 21, 0.3);
}

.revenant-filter {
  filter: invert(14%) sepia(93%) saturate(519%) hue-rotate(291deg) brightness(93%) contrast(89%);
}

.revenant-filter:hover {
  transition: filter 0.3s ease-out;
  filter: invert(6%) sepia(10%) saturate(4148%) hue-rotate(292deg) brightness(94%) contrast(97%);
}