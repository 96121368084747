.equipment-fashion {
    min-width: 355px;
    width: 0px;
    min-height: 565px;
    height: auto;
    padding: 20px 0;
    background-color: #202124;
    margin: 5px;
}

.box {
    width: 21px;
    height: 21px;
    margin: 2px;
}

.box-long {
    width: 47px;
    height: 21px;
    margin: 2px;
}

.box-big {
    width: 46px;
    height: 46px;
    margin: 2px;
}

.infusion-box {
    display: flex;
    margin: 2px;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
}

.infusion-box-big {
    display: flex;
    margin: 2px;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
}

.box-infusion {
    border: 2px solid gray;
}

.wiki-infusion {
    margin-left: -20px;
    margin-top: 0px;
}

.vertical-text {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    width: 15px;
    height: 258px;
    padding: 0px;
    font-size: 10px;
    color: gray;
}