.characters-boxes {
    cursor: pointer;
    min-width: 228px;
    max-width: 228px;
}

.accounts-link {
    text-decoration: none;
}

.accounts-box {
    border: 1.5px solid rgb(255, 255, 255);
    box-shadow: 0 0 7px 2px rgba(152, 152, 152, 0.2);
    margin: 1%;
    color: white;
}

.accounts-hover {
    transition: background-color 0.3s ease-out;
}

.accounts-hover:hover {
    box-shadow: 0 0 7px 2px rgba(204, 204, 204, 0.3);
    background-color: #35363a;
}

.accounts-name {
    margin-block-start: 0em;
    margin-block-end: 0em;
    height: 50px;
    text-align: center;
    font-size: larger;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
}

.column {
    display: flex;
    flex-direction: column;
}

.accounts-flex {
    display: flex;
    justify-content: space-around;
}

.accounts-col {
    align-items: center
}

.accounts-h6 {
    margin-block-start: 0;
}

.white-info {
    filter: invert(99%) sepia(100%) saturate(2%) hue-rotate(178deg) brightness(106%) contrast(100%);
}

.account-popup {
    background-color: #000000e0;
    padding: 10px;
    white-space: nowrap;
    font-size: smaller;
    white-space: pre-wrap;
    text-align: left;
    max-width: 170px;
}

.home-empty-search-box {
    margin: 0 10px;
    margin-top: 0.5em;
    padding: 1em 0.6em;
    background-color: #202124;
    box-shadow: 0 0 7px 2px rgba(204, 204, 204, 0.2);
    color: #d3d3d3;
    width: 44em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 1.2em;
}

.search-news {
    text-align: left;
    padding: 1% 10%;
}

/* Pagination */
.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.pagination-item {
    margin: 0 2px;
    cursor: pointer;
    border: 1.5px solid white;
    color: white;
    background-color: #202124;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: medium;
}

.pagination-item:hover {
    background-color: #aa0404;
    box-shadow: 0 0 7px 2px rgba(204, 204, 204, 0.2);
}

.pagination-item:disabled {
    background-color: #35363a;
    cursor: default;
    opacity: 0.6;
    pointer-events: none;
}

.pagination-item.active {
    background-color: #aa0404;
    color: white;
}

.search-menu-flex {
    flex-wrap: wrap;
    justify-content: center;
}

.dropdown-component {
    margin-top: -10px;
    opacity: 0;
    transition: margin-top 0.5s ease, opacity 0.5s ease;
}

.dropdown-component.visible {
    margin-top: 0;
    opacity: 1;
}

.basic-button-search {
    font-family: 'Jost';
    cursor: pointer;
    background-color: #202124;
    color: #d9d9d9;
    border: none;
    margin: 5px 4px;
    padding: 2px 3px;
    font-size: 0.9em;
    width: 88px;
}

.basic-button-search:hover {
    background-color: #585a5f;
    box-shadow: 0 0 7px 2px rgba(255, 255, 255, 0.7);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.basic-button-search.active {
    background-color: #424246;
    box-shadow: 0 0 7px 2px rgba(255, 255, 255, 0.7);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.basic-button-search div img {
    width: 30px;
    margin-top: 5px;
    margin-bottom: -5px;
}

.x-button {
    background-color: transparent;
    color: white;
    border: none;
    padding: 0px 10px;
    margin: 8px 5px 8px -44px;
    font-size: 1.5em;
    box-shadow: none !important;
}

.x-button:hover {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #d70000;

}

.plus-minus-button {
    background-color: #202124;
    color: white;
    border: none;
    padding: 0px 5px;
    margin: 5px 10px;
    width: 35px;
    font-size: 1.5em;
}

.selected-span {
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
}

.selected-span:hover {
    color: #d70000;
}

.selected-span::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 100%;
    height: 2px;
    background-color: #d70000;
    color: #d70000;
    transform: translateY(-50%);
    transition: right 0.5s ease;
    z-index: -1;
}

.selected-span:hover::before {
    right: 0;
    text-decoration: none;
}

.hr-container {
    position: relative;
}

.thin-hr {
    height: 1px;
    background-color: #a3a3a3;
    right: 0;
    transform: scaleY(0.5);
    transform-origin: top;
    width: 90%;
    box-shadow: 0 0 5px 2px rgba(204, 204, 204, 0.2);
}

.thin-hr-vertical {
    border-left: 1px groove #afaeae;
    transform: scaleY(0.65);
    margin: 0 10px;
    opacity: 0.6;
    box-shadow: 0 0 2px 3px rgba(204, 204, 204, 0.1);
}

.transition-hover-search,
.dropdown-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, z-index 0.3s ease-in-out;
    /* You can adjust the duration and easing function */
    z-index:-1 !important;
}

.transition-hover-search.open,
.dropdown-menu.open {
    max-height: 1000px;
    z-index:10 !important;
    /* Adjust the maximum height as needed */
}