.equipment {
    min-width: 280px;
    max-width: 484px;
    width: 100%;
    min-height: 652px;
    padding: 20px 0;
    background-color: #202124;
    margin: 4px;
}

.equipment-save {
    min-width: 280px;
    max-width: 345px;
    width: 100%;
    padding: 20px 0;
    background-color: #202124;
    margin: 4px;
}

.equipment-box {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.switch-size-left {
    text-align: left;
    padding: 10px 10px 5px 0px;
}

.switch-size-right {
    text-align: left;
    padding: 0px 5px 0px 0px;
}

/* NEW SWITCH START */
.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl+.tgl-btn {
    box-sizing: border-box;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl+.tgl-btn::selection {
    background: none;
}

.tgl+.tgl-btn {
    outline: 0;
    display: block;
    width: 2.4em;
    height: 1.5em;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.tgl+.tgl-btn:after,
.tgl+.tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl+.tgl-btn:after {
    left: 0;
}

.tgl+.tgl-btn:before {
    display: none;
}

.tgl:checked+.tgl-btn:after {
    left: 50%;
}

.tgl-light+.tgl-btn {
    background: #f0f0f0;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
}

.tgl-light+.tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
}

.tgl-skewed+.tgl-btn {
    overflow: hidden;
    transform: skew(0deg);
    backface-visibility: hidden;
    transition: all 0.2s ease;
    background: #888;
}

.tgl-skewed+.tgl-btn:after,
.tgl-skewed+.tgl-btn:before {
    transform: skew(0deg);
    display: inline-block;
    transition: all 0.2s ease;
    width: 100%;
    text-align: center;
    position: absolute;
    line-height: 1.5em;
    font-weight: bold;
    font-size: 13px;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.tgl-skewed+.tgl-btn:after {
    left: 100%;
    content: attr(data-tg-on);
}

.tgl-skewed+.tgl-btn:before {
    left: 0;
    content: attr(data-tg-off);
}

.tgl-skewed+.tgl-btn:active {
    background: #888;
}

.tgl-skewed:checked+.tgl-btn:before {
    left: -100%;
}

.tgl-skewed:checked+.tgl-btn:after {
    left: 0;
}

.tgl-skewed:checked+.tgl-btn:active:after {
    left: 10%;
}

/* NEW SWITCH STOP */

.box-hover {
    transition: transform 0.2s, filter 0.2s;
}

.box-hover:hover {
    transform: scale(1.03);
    filter: brightness(1.2);
}

.item-box {
    display: flex;
    margin: 3px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    transition: transform 0.2s, filter 0.2s;
}

.item-box:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
}

.dye-hover {
    transition: transform 0.2s, filter 0.2s;
}

.dye-hover:hover {
    transform: scale(1.2);
    filter: brightness(1);
}


.equipment-col-1 {
    margin-left: 0;
    width: 210px;
}

.item-popup {
    background-color: #000000e0;
    padding: 10px;
    white-space: nowrap;
    font-size: smaller;
    max-width: 300px;
    width: 80%;
    white-space: pre-wrap;
    text-align: left;
}

.item-popup-fashion {
    width: 140px;
    z-index: 9999 !important;
}

.item-name {
    font-size: medium;
    font-weight: 500;
}

.attribute {
    padding: 2px 20px 2px 0;
}

.attribute-popup {
    background-color: #000000e0;
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 100px;
    font-size: small;
    text-align: center;
}

.attribute-popup img {
    width: 20px;
    height: 20px;
}

.equip-tooltip {
    background-color: #000000e0;
    display: flex;
    justify-content: center;
    padding: 5px;
    font-size: small;
    text-align: center;
}

.itemstats {
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px;
    background: #202124;
    width: 441px;
    text-align: left;
    z-index: 6;
    margin-top: 0px;
    margin-bottom: -20px;
}

.itemstat {
    margin-top: 10px;
}

.itemtypes {
    font-size: 12px;
}

.attributes-unzoom {
    transform: scale(0.82);
    transform-origin: top;
}

.itemname {
    color: #5192f2;
}

.italic {
    font-style: italic;
}

.green {
    color: #32e532;
}

.upgrade-blue {
    color: #5192f2;
}

.upgrade-gray {
    color: #a0a0a0;
}

.box-gray {
    border: 3px solid gray;
}

.box-gray-colors:hover {
    transform: none;
    filter: none;
}

.name-unknown {
    color: #f30606;
}

.box-unknown {
    border: 3px solid #f30606;
}

.border-unknown {
    border: 1.5px solid rgb(243, 6, 6);
    box-shadow: 0 0 10px 2px rgba(192, 4, 4, 0.3);
}


.name-basic {
    color: #ffffff;
}

.box-basic {
    border: 3px solid #ffffff;
}

.border-basic {
    border: 1.5px solid rgb(255, 255, 255);
    box-shadow: 0 0 10px 2px rgba(204, 204, 204, 0.3);
}

.name-fine {
    color: #62a4e1;
}

.box-fine {
    border: 3px solid #62a4e1;
}

.border-fine {
    border: 1.5px solid rgb(98, 164, 225);
    box-shadow: 0 0 10px 2px rgba(75, 126, 174, 0.3);
}

.name-masterwork {
    color: #1a9306;
}


.box-masterwork {
    border: 3px solid #1a9306;
}

.border-masterwork {
    border: 1.5px solid rgb(26, 147, 6);
    box-shadow: 0 0 10px 2px rgba(16, 95, 3, 0.3);
}

.name-rare {
    color: #fcd00b;
}

.box-rare {
    border: 3px solid #fcd00b;
}

.border-rare {
    border: 1.5px solid rgb(252, 208, 11);
    box-shadow: 0 0 10px 2px rgba(201, 165, 8, 0.3);
}

.name-exotic {
    color: #ffa405;
}

.box-exotic {
    border: 3px solid #ffa405;
}

.border-exotic {
    border: 1.5px solid rgb(255, 164, 5);
    box-shadow: 0 0 10px 2px rgba(204, 131, 4, 0.3);
}

.name-ascended {
    color: #fb3e8d;
}

.box-ascended {
    border: 3px solid #fb3e8d;
}

.border-ascended {
    border: 1.5px solid rgb(251, 62, 141);
    box-shadow: 0 0 10px 2px rgba(199, 49, 112, 0.3);
}


.name-legendary {
    color: #5d17cf;
}

.box-legendary {
    border: 3px solid #5d17cf;
}

.border-legendary {
    border: 1.5px solid rgb(93, 23, 207);
    box-shadow: 0 0 10px 2px rgba(76, 19, 169, 0.3);
}